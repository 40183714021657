<template>
  <div class="pa-5">
    <v-card class="py-5 px-8" color="rgba(255, 255, 255, 0.7)">
      <div class="text-h6 font-weight-bold accent--text">新增報修</div>
      <v-row no-gutters class="mt-5">
        <v-col cols="12" md="6">
          <v-form ref="form" v-model="formValid" lazy-validation>
            <v-text-field
              outlined
              background-color="white"
              label="標題"
              v-model="ticket.name"
              required
              :rules="[rules.required, rules.maxLength30]"
            />
            <v-textarea
              outlined
              background-color="white"
              label="狀況說明"
              v-model="ticket.content"
              required
              :rules="[rules.required, rules.maxLength1000]"
            />
            <v-text-field
              outlined
              background-color="white"
              label="申告人"
              v-model="ticket.reporter"
              required
              :rules="[rules.required, rules.maxLength10]"
            />
            <v-text-field
              outlined
              background-color="white"
              label="email"
              v-model="ticket.reporter_email"
              required
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              outlined
              background-color="white"
              label="電話"
              v-model="ticket.reporter_telephone"
              required
              :rules="[rules.required, rules.phone]"
            />
            <!-- <v-text-field
              outlined
              background-color="white"
              label="縣市"
              v-model="ticket.area_id"
            />
            <v-text-field
              outlined
              background-color="white"
              label="學校"
              v-model="ticket.school_id"
            /> -->
            <v-file-input
              outlined
              background-color="white"
              label="上傳照片"
              outlined
              multiple
              v-model="ticket.attached_files"
              accept="image/*"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label>
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ ticket.attached_files.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>
            <div class="d-flex">
              <v-spacer />
              <v-btn
                :disabled="!formValid"
                color="primary"
                min-width="100"
                class="font-weight-bold"
                large
                @click="submit"
                >送出</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Ticket from '@/api/Ticket'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      ticket: {},
      formValid: false,
      rules: {
        required: (v) => !!v || '此欄位必填',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'email 格式錯誤'
        },
        maxLength30: (v) =>
          (v && v.length <= 30) || '最多可輸入30個中/英文、數字及標點符號',
        maxLength1000: (v) =>
          (v && v.length <= 1000) || '最多可輸入1,000個中/英文、數字及標點符號',
        maxLength10: (v) => {
          let excludeNumer = new RegExp(/^[^0-9]+$/)
          return (
            (v && v.length <= 10 && excludeNumer.test(v)) ||
            '最多可輸入10個中/英文及標點符號'
          )
        },
        phone: (v) => {
          let mobileCheck = new RegExp(/^[0][9]\d{8}$/)
          let telCheck = new RegExp(/^0[2-8]\d{0,8}(?:#\d+)*$/)
          if (!(mobileCheck.test(v) || telCheck.test(v))) {
            return '請輸入10碼手機號碼或市話(市話無分機格式如0437061800、有分機格式如0437061800#12345)'
          }
          return true
        },
        fileSize: (files) =>
          !files ||
          !files.some((file) => file.size > 3_145_728) ||
          '請選擇 jpg, jpeg, png 格式檔案，檔案大小不可超過 3MB'
      }
    }
  },
  computed: {
    ...mapGetters({
      isIlan: 'page/isIlan',
      tags: 'page/tags',
      schools: 'page/schools'
    })
  },
  mounted() {
    if (this.isIlan) this.$router.push({ name: '404' })
  },
  methods: {
    ...mapActions({
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    submit() {
      if (this.$refs.form.validate()) this.createTicket()
    },
    async createTicket() {
      try {
        this.setOverlayLoading(true)
        let formData = new FormData()
        let fullTicket = {
          ...this.ticket,
          area_id: this.tags.name,
          school_id: this.schools[0].name
        }
        for (const [key, value] of Object.entries(fullTicket)) {
          if (key === 'attached_files' && this.ticket.attached_files?.length) {
            this.ticket.attached_files.forEach((element, index) => {
              formData.append('attached_files[' + index + ']', element)
            })
          } else formData.append(key, value)
        }
        formData.append('process_description', '')
        await Ticket.create(formData)
        Swal.fire({
          title: '建立成功',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          width: 250
        })
        this.$refs.form.reset()
      } catch (err) {
        console.error(err)
        Swal.fire({
          title: '建立失敗',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          width: 300
        })
      } finally {
        this.setOverlayLoading(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input:not(.v-input--is-focused) {
  fieldset {
    border-color: #889bb7;
  }
  .v-label {
    color: #889bb7;
  }
}
</style>
