import settings from '@/settings'

class _Ticket {
  // 新增工單
  create(formData) {
    const issueReportPath = window.location.origin.includes('edu.tw')
      ? 'https://iot-issue-report-test.fetnet.net/api'
      : 'https://iot-issue-report-dev.fetnet.net/api'
    return axios.post(`${issueReportPath}/job_ticket/v1/`, formData, {
      headers: {
        Authorization:
          'Bearer ee36fab4-ff1b-11eb-a595-f45c89b99a23:user_test_all',
        'X-Authorization-mode': 'AUTHORIZATION_MODE_SECRET_KEY'
      }
    })
  }
}

var Ticket = new _Ticket()
export default Ticket
